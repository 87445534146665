<template>
  <b-sidebar
    id="add-new-cohort-sidebar"
    :visible="isAddNewCohortSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-cohort-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New Cohort</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Name -->
          <validation-provider #default="validationContext" name="Cohort Name" rules="required">
            <b-form-group label="Cohort Name" label-for="cohort-name">
              <b-form-input
                id="cohort-name"
                v-model="cohortData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Richmond VETs w/ TS POLY"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
              <v-select
                v-model="cohortData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="cohort-status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                      @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onUnmounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import placementStoreModule from "@/views/apps/placement/placementStoreModule";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: "isAddNewCohortSidebarActive",
    event: "update:is-add-new-cohort-sidebar-active"
  },
  props: {
    isAddNewCohortSidebarActive: {
      type: Boolean,
      required: true
    },
    visibilityOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      required,
      alphaNum
    };
  },
  setup(props, { emit }) {
    const COHORT_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(COHORT_APP_STORE_MODULE_NAME))
      store.registerModule(COHORT_APP_STORE_MODULE_NAME, placementStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COHORT_APP_STORE_MODULE_NAME)) store.unregisterModule(COHORT_APP_STORE_MODULE_NAME);
    });

    const blankCohortData = {
      name: "",
      status: "created"
    };

    const cohortData = ref(JSON.parse(JSON.stringify(blankCohortData)));
    const resetcohortData = () => {
      cohortData.value = JSON.parse(JSON.stringify(blankCohortData));
    };

    const onSubmit = () => {
      console.log(`creating cohort`, cohortData.value);
      store.dispatch("app-placement/addCohort", { cohortData: cohortData.value })
        .then(response => {
          emit("refetch-data");
          emit("update:is-add-new-cohort-sidebar-active", false);

          if (response.data.id) {
            this.$router.push({name: 'app-placement-cohort-view', params: {id: response.data.id}})
          }

        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetcohortData);

    return {
      cohortData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm
    };
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-cohort-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
